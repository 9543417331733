<template>
        <div class="common-form">
            <div class="common-form-layout ">
                <global-page-back
                    detailPageTitle="异常推送设置"
                    @handlerGlobalType="handlerGlobalType"
                >
                </global-page-back>

                <div class="common-form-wrapper content-bg">
                    <div class="common-form-main common-form-layout--scroll">
                        <div class="form-title">异常推送设置</div>
                        <div class="common-form-view" style="text-align: left">
                            <el-skeleton v-if="loading" :rows="10" style="max-width: 750px;margin: 20px"></el-skeleton>
                            <el-form v-else label-width="120px"  label-position="right" class="common-form">
                                <el-card class="box-card" v-if="false">
                                    <div class="title">宿舍核对</div>
                                    <el-form-item label="异常通知">
                                        <el-switch v-model="ruleForm.checkSwitch" active-value="1" inactive-value="2" class="input-width-small"></el-switch>
                                        <div class="tips">超出核对截止时间未核对，视为核对异常</div>
                                    </el-form-item>
                                    <el-form-item label="通知人">
                                        <el-select
                                            :value="ruleForm.checkPerson.map(i => i.id)"
                                            multiple
                                            @remove-tag="(data) => handleRemove(data, 'treeRef1')"
                                            @visible-change="handleVisible1"
                                            class="input-width-small"
                                            placeholder="请选择通知人"
                                        >
                                            <el-option
                                                v-show="false"
                                                v-for="item in ruleForm.checkPerson"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            />
                                            <el-input placeholder="输入关键字进行过滤" v-model="filterText" class="search-input"></el-input>
                                            <el-option value="" label="">
                                                <vue-easy-tree
                                                    node-key="id"
                                                    ref="treeRef1"
                                                    height="200px"
                                                    lazy
                                                    :load="loadNode"
                                                    :data="options"
                                                    :props="defaultProps"
                                                    :expand-on-click-node="true"
                                                    :default-checked-keys="ruleForm.checkPerson.map(i => i.id)"
                                                    @check="handleCheck1"
                                                    show-checkbox
                                                >
                                                </vue-easy-tree>
                                            </el-option>
                                        </el-select>
                                        <div>
                                            <el-checkbox v-model="ruleForm.checkOwn" class="check-box" true-label="1" false-label="2"
                                                    style="top: 0">包含本人
                                            </el-checkbox>
                                        </div>
                                    </el-form-item>
                                </el-card>
                                <el-card class="box-card">
                                    <div class="title">宿舍检查</div>
                                    <el-form-item label="异常通知">
                                        <el-switch v-model="ruleForm.inspectSwitch" active-value="1" inactive-value="2" class="input-width-small"></el-switch>
                                        <div class="tips">超出检查截止时间未检查、检查结果与核对结果不一致，视为检查异常</div>
                                    </el-form-item>
                                    <el-form-item label="通知人">
                                        <el-select
                                            :value="ruleForm.inspectPerson.map(i => i.id)"
                                            multiple
                                            @remove-tag="(data) => handleRemove(data, 'treeRef2')"
                                            @visible-change="handleVisible2"
                                            class="input-width-small"
                                            placeholder="请选择通知人"
                                        >
                                            <el-option
                                                v-show="false"
                                                v-for="item in ruleForm.inspectPerson"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            />
                                            <el-input placeholder="输入关键字进行过滤" v-model="filterText2"
                                                    class="search-input"></el-input>
                                            <el-option value="" label="">
                                                <vue-easy-tree
                                                    node-key="id"
                                                    ref="treeRef2"
                                                    lazy
                                                    :load="loadNode1"
                                                    :data="options"
                                                    :props="defaultProps"
                                                    :expand-on-click-node="true"
                                                    :default-checked-keys="ruleForm.inspectPerson.map(i => i.id)"
                                                    @check="handleCheck2"
                                                    show-checkbox
                                                    height="200px"
                                                >
                                                </vue-easy-tree>
                                            </el-option>
                                        </el-select>
                                        <div>
                                            <el-checkbox v-model="ruleForm.inspectOwn" class="check-box" true-label="1" false-label="2">
                                                包含本人
                                            </el-checkbox>
                                            <el-checkbox v-model="ruleForm.inspectCheck" class="check-box" true-label="1" false-label="2">
                                                包含核对人（相关核对人）
                                            </el-checkbox>
                                        </div>
                                    </el-form-item>
                                </el-card>
                            </el-form>
                        </div>
                    </div>
                </div>
                <form-submit-btn>
                    <div slot="formSubmitBtn">
                        <el-button type="primary" margin-left="20px" @click="handleSave" v-hasPermi="['attendanceError:save']">
                            保存
                        </el-button>
                    </div>
                </form-submit-btn>

            </div>
        </div>
</template>

<script>
import {
    MTreeSelect, debounce,
} from "common-local";
import GlobalPageBack from './Sub/GlobalPageBack/index.vue';
import FormSubmitBtn from "./Sub/FormSubmitBtn/index.vue";
import {CampusSafetyModel} from "@/models/CampusSafety"
import {mapState} from "vuex";
import {listToTree} from "@/libs/utils";

import VueEasyTree from "@wchbrad/vue-easy-tree";
import "@wchbrad/vue-easy-tree/src/assets/index.scss"
import {CommonModel} from "@/models/Common";

export default {
    components: {
        MTreeSelect,
        VueEasyTree,
        GlobalPageBack,
        FormSubmitBtn
    },
    data() {
        return {
            loading: true,
            defaultProps: {
                label: 'name',
                value: 'id',
                isLeaf: 'leaf'
            },
            ruleForm: {
                id: '',
                productId: '',
                schoolId: '',
                inspectSwitch: '2',// 检查异常通知开关1开2关
                inspectOwn: '1', // 检查通知是否通知本人1是2否
                inspectCheck: '1', // 包含核对人
                inspectPerson: [], // 检查通知人员json
                checkSwitch: '2', // 核对异常通知开关1开2关
                checkOwn: '1', // 核对通知是否通知本人1是2否
                checkPerson: [],// 核对通知人员json
            },
            options: [],
            oriOptions: [],
            filterText: '',
            filterText2: '',
        }
    },
    watch: {
        filterText(val) {
            this.filterNode(val)
        },
        filterText2(val) {
            this.filterNode(val)
        },
        'ruleForm.checkPerson': {
            handler (newVal) {
                console.log(newVal,'ruleForm.checkPerson')
            },
            deep: true
        }
    },
    computed: {
        productId() {
            return 'cloud_campus'
        },
        ...mapState({
            schoolId: state => state.schoolId
        })
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            this.loading = true;
            this.ruleForm.schoolId = this.schoolId
            this.ruleForm.productId = this.productId

            await this.getTreeData()
            await this.getList()
            await this.getTeacherList();
            this.loading = false;
        },
        async getList() {
            const campusSafety = new CampusSafetyModel()
            const params = {
                schoolId: this.schoolId,
                productId: this.productId
            }
            await campusSafety.getMessageSetting(params).then((res) => {
                if (res.data.code === '200' && res.data.data) {
                    Object.keys(this.ruleForm).forEach((key) => {
                        if (!['checkPerson', 'inspectPerson'].includes(key)) {
                            this.ruleForm[key] = res.data.data[key]
                        }
                    })

                    this.$nextTick(() => {
                        if (res.data.data.checkPerson) {
                            this.ruleForm.checkPerson = res.data.data.checkPerson.split(',').map((item) => ({
                                id: item,
                                name: ''
                            }))
                        }

                        if (res.data.data.inspectPerson) {
                            this.ruleForm.inspectPerson = res.data.data.inspectPerson.split(',').map((item) => ({
                                id: item,
                                name: ''
                            }))
                        }
                    })
                }

            })
        },
        async getTeacherList () {
            var checkPerson = [],
                inspectPerson = [];

            this.ruleForm.checkPerson.forEach((item) => {
                checkPerson.push(item.id);
            })
            checkPerson.toString();

            this.ruleForm.inspectPerson.forEach((item) => {
                inspectPerson.push(item.id);
            })
            inspectPerson.toString();

            const campusSafety = new CampusSafetyModel();
            await campusSafety.getTeacherByIds({ids: checkPerson}).then((res) => {
                if (res.status == '0') {
                    res.data.forEach((item) => {
                        this.ruleForm.checkPerson.forEach((subItem) => {
                            if (item.id == subItem.id) {
                                subItem.name = item.name
                            }
                        })
                    })
                }
            })

            await campusSafety.getTeacherByIds({ids: inspectPerson}).then((res) => {
                if (res.status == '0') {
                    res.data.forEach((item) => {
                        this.ruleForm.inspectPerson.forEach((subItem) => {
                            if (item.id == subItem.id) {
                                subItem.name = item.name
                            }
                        })
                    })
                }
            })
        },
        // 获取人员信息
        async getTreeData() {
            const commonModel = new CommonModel();
            commonModel.getAddressBookList({
                schoolId: this.schoolId,
                type: "teacher,organ",
                parentId: "",
                name: "",
                organType: "1,5"
            }).then((res) => {
                if (res.data.code === "200") {
                    this.oriOptions = res.data.data
                    this.options = res.data.data;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        filterNode:debounce(function (value) {
            if (value) {
                const commonModel = new CommonModel();
                commonModel.getAddressBookList({
                    schoolId: this.schoolId,
                    type: "teacher,organ",
                    parentId: null,
                    name: value,
                    organType: "1,5"
                }).then((res) => {
                    if (res.data.code === "200") {
                        res.data.data.forEach((item) => {
                            this.$set(item, 'leaf', item.type === 'teacher')
                        })
                        this.options = res.data.data
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
            } else {
                this.options = this.oriOptions
            }
        }, 200),
        handleRemove(data, ref) {
            this.$refs[ref].setChecked(data, false)
            console.log(data,'ref')
            switch (ref) {
                case 'treeRef1':
                    this.ruleForm.checkPerson.forEach((item, index) => {
                        if (item.id == data) {
                            this.ruleForm.checkPerson.splice(index, 1)
                        }
                    })
                    break;
                case 'treeRef2':
                    this.ruleForm.inspectPerson.forEach((item, index) => {
                        if (item.id == data) {
                            this.ruleForm.inspectPerson.splice(index, 1)
                        }
                    })
                    break;

            }
        },
        handleVisible1() {
            this.filterText = ''
        },
        handleVisible2() {
            this.filterText2 = ''
        },
        handleCheck1(data, data1) {
            let teachers = data1.checkedNodes.filter(item => item.type === 'teacher')
            this.ruleForm.checkPerson = this._.uniqBy(teachers, 'id').map((item) => ({
                id: item.id,
                name: item.name
            }))
        },
        handleCheck2(data, data1) {
            let teachers = data1.checkedNodes.filter(item => item.type === 'teacher')
            this.ruleForm.inspectPerson = this._.uniqBy(teachers, 'id').map((item) => ({
                id: item.id,
                name: item.name
            }))
        },
        handleSave() {
            let form = JSON.parse(JSON.stringify(this.ruleForm))
            form.checkOwn = "2"
            form.checkPerson = []
            form.checkSwitch = "2"
            if (form.inspectSwitch === '1' && form.inspectPerson.length === 0) {
                return this.$message.warning('请选择检查异常通知人')
            }
            if (form.checkSwitch === '1' && form.checkPerson.length === 0) {
                return this.$message.warning('请选择核对异常通知人')
            }
            form.inspectPerson = form.inspectPerson.map(i => i.id).toString()
            form.checkPerson = form.checkPerson.map(i => i.id).toString()
            // form.inspectOwn = form.inspectOwn ? '1' : '2'
            // form.checkOwn = form.checkOwn ? '1' : '2'
            // form.inspectCheck = form.inspectCheck ? '1' : '2'
            const campusSafety = new CampusSafetyModel()
            campusSafety.saveMessageSetting(form).then( async (res) => {
                if (res.data.code === '200') {
                    this.$message.success('保存成功')
                    await this.getList()
                    await this.getTeacherList();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 懒加载
         * @DoWhat: 懒加载
         * @UseScenarios: 懒加载
         * @Attention: 懒加载
         * @Author: 武东超
         * @Date: 2022-12-08 11:28:16
         */
         loadNode (node, resolve) {
            let checkPerson = [];
            this.ruleForm.checkPerson.forEach((item) => {
                checkPerson.push(item.id)
            })
             //如果展开第一级节点，从后台加载一级节点列表
            if (node.level == 0) {
                if (this.$refs.treeRef1) {
                    this.$refs.treeRef1.setCheckedKeys(checkPerson)
                }

                return resolve(this.options)
            }
            //如果展开其他级节点，动态从后台加载下一级节点列表
            if (node.level >= 1) {
                const commonModel = new CommonModel();
                commonModel.getAddressBookList({
                    schoolId: this.schoolId,
                    type: "teacher,organ",
                    parentId: node.data.id,
                    name: "",
                    organType: "1,5"
                }).then((res) => {
                    if (res.data.code === "200") {
                        res.data.data.forEach((item) => {
                            this.$set(item, 'leaf', item.type === 'teacher')
                        })
                        resolve(res.data.data)
                        if (this.$refs.treeRef1) {
                            this.$refs.treeRef1.setCheckedKeys(checkPerson)
                        }
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        loadNode1 (node, resolve) {
            let inspectPerson = [];
            this.ruleForm.inspectPerson.forEach((item) => {
                inspectPerson.push(item.id)
            })

            if (node.level == 0) {
                if (this.$refs.treeRef2) {
                    this.$refs.treeRef2.setCheckedKeys(inspectPerson)
                }
                return resolve(this.options)
            }
            //如果展开其他级节点，动态从后台加载下一级节点列表
            if (node.level >= 1) {
                const commonModel = new CommonModel();
                commonModel.getAddressBookList({
                    schoolId: this.schoolId,
                    type: "teacher,organ",
                    parentId: node.data.id,
                    name: "",
                    organType: "1,5"
                }).then((res) => {
                    if (res.data.code === "200") {
                        res.data.data.forEach((item) => {
                            this.$set(item, 'leaf', item.type === 'teacher')
                        })
                        resolve(res.data.data)
                        if (this.$refs.treeRef2) {
                            this.$refs.treeRef2.setCheckedKeys(inspectPerson)
                        }
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        /**
         * @Description: 返回列表页面
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-07-21 15:54:21
         */
        handlerGlobalType() {
            this.$emit("changeType", 1)
        }
    },
}
</script>
<style scoped lang="scss">
.common-form-wrapper {
    white-space: normal;
    margin-top: 0px;
    padding-top: 10px;

    .form-title{
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2B2E33;
        border-bottom: 1px solid #F5F5F5;
        padding-bottom: 12px;
    }

    .box-card {
        margin: 14px auto 20px;
        max-width: 683px;
        .title{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            text-align: center;
        }

        .tips {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
        }
    }
}

.search-input {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
    height: auto;
    background-color: #fff !important;
    padding: 0 10px;
}

/deep/ .el-tree-node__content {
    height: 34px;
}
/deep/ .common-form .el-form-item__label{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333
}

</style>
